import store from '@/store'
import { lazyLoad } from '../../index'

export default {
  path: 'products',
  component: lazyLoad('Admin/Data/Products'),
  children: [
    {
      path: '',
      component: lazyLoad('Admin/Data/Products/List')
    },
    {
      path: 'download',
      component: lazyLoad('Admin/Data/Products/Download')
    },
    {
      path: 'upload',
      component: lazyLoad('Admin/Data/Products/Upload')
    },
    {
      path: 'archived',
      component: lazyLoad('Admin/Data/Products/Archived')
    }
  ]
}
